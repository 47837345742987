import * as m from "mithril";
import {Vnode} from "mithril";
import * as Mousetrap from "mousetrap";

let props = {
    api: "http://127.0.0.1:8282",
};

const max_tweets = 400;

let xhr = {
    lastRequest: 0,

    ajax: function (state: JQueryAjaxSettings): JQueryXHR {
        xhr.lastRequest = Date.now();
        return $.ajax(state);
    }
};

interface User {
    screenName: string,
}

interface Pic {
    url: string,
    width: number,
    height: number
}

interface Friend {
    name: string,
    atName: string,
    tweets: number,
    following: number,
    followers: number,
    location: string,
    bio: string,
    url: string,
    listed: number
    id: string,
    createdAt: number
}

interface Tweet {
    id: string,
    time: number,
    user: User,
    screenName: string,
    text: string,
    retweetStatus?: Tweet,
    urls?: string[],
    pics?: Pic[],
    source?: string,
}

class Timeline {
    name: string;
    list: Tweet[];
    listMap: { [index: string]: number } = {};
    sel: Sel;
    back: string;
    lastPulled: string;
    unreadById: { [index: string]: Tweet } = {};
    scrollTop: number;
    friend: Friend;

    constructor(name: string, list: Tweet[], sel: Sel) {
        this.name = name;
        this.list = list;
        this.sel = sel;
    }

    setList(list: Tweet[]) {
        this.list = list;
        this.listMap = {};
        for (let i = 0; i < list.length; i++) {
            let tweet = list[i];
            this.listMap[tweet.id] = i;
        }
    }

    prepend(tweets: Tweet[]) {
        this.list = tweets.concat(this.list);
        this.setList(this.list);
    }

    saveScrollTop() {
        this.scrollTop = app.scroller().scrollTop;
        // this.scrollTop = document.body.scrollTop;
    }

    moveSel(dir: number) {
        if (this.list.length == 0) {
            this.sel.index = 0;
            return;
        }
        this.sel.index = this.sel.index + dir;
        if (this.sel.index < 0) this.sel.index = 0;
        if (this.sel.index >= this.list.length) this.sel.index = this.list.length - 1;
        this.setSel(this.sel.index);
    }

    getTweet() {
        if (this.list.length === 0) return null;
        return this.list[this.sel.index];
    }

    setSel(index: number): Tweet {
        let tweet = this.list[index];
        this.sel.id = tweet.id;
        this.sel.index = index;
        // dont store selection for other tabs
        let storeSel = this.name == 'pol' || this.name == 'timeline';

        if (storeSel) {
            localStorage.setItem(`sel.${this.name}.id`, this.sel.id);
            if (this.sel.index == 0)
                localStorage.setItem(`mostRecent.${this.name}.id`, this.sel.id);
        }
        return tweet;
    }

    selectId(id: string) {
        let index = this.listMap[id];
        if (index === undefined) return;
        this.setSel(index);
    }
}

interface Sel {
    index: number,
    id: string
}

let FriendDiv = {
    view: function (vnode: Vnode<any>) {
        let tweet = vnode.attrs['tweet'];
        let tab = app.openTab();
        let friend = tab.friend;
        if (friend == null) return;

        let userName = tweet.user.name;
        let atName = tweet.user.screenName;
        let userPic = tweet.user.pic;
        if (tweet.retweetStatus) {
            userName = tweet.retweetStatus.user.name;
            atName = tweet.retweetStatus.user.screenName;
            userPic = tweet.retweetStatus.user.pic;
        }

        return m('div', [
            m('article', {
                    class: 'media ', 'data-id': tweet.id,
                    key: tweet.id,
                }, [
                    m('figure', {class: 'media-left'}, [
                        m('p', {class: 'image is-48x48'}, [
                            m('img', {src: userPic, key: tweet.id + '_img'})
                        ])
                    ]),
                    m('div', {class: 'media-content'}, [
                        m('div', {class: 'content'}, [
                            m('p', [
                                m('strong', userName),
                                m('small', ` @${atName}`),
                                m('p', friend.bio),
                                m('p', m.trust(tweet.source)),
                                m('span', friend.location), m('br'),
                                m('a', {href: friend.url}, friend.url),
                                // m.trust(tweet.text)
                            ])
                        ]),
                    ]),
                ]
            ),
            m('nav', {class: 'level tweetbar is-mobile'}, [
                m('div', {class: 'level-item has-text-centered'}, [
                    m('p', {class: 'heading'}, 'Tweets'),
                    m('p', {class: 'title'}, friend.tweets.toLocaleString())
                ]),
                m('div', {class: 'level-item has-text-centered'}, [
                    m('p', {class: 'heading'}, 'Following'),
                    m('p', {class: 'title'}, friend.following.toLocaleString())
                ]),
                m('div', {class: 'level-item has-text-centered'}, [
                    m('p', {class: 'heading'}, 'Followers'),
                    m('p', {class: 'title'}, friend.followers.toLocaleString())
                ]),
            ]),
        ])
    }
};


let TweetDiv = {
    onbeforeupdate: function (vnode: Vnode<any, any>, old: Vnode<any, any>): boolean {
        // console.log(`onbeforeupdate ${vnode.attrs.col}`);
        return true;

        // let tab = app.openTab();
        // let tabName = tab.name;
        // let colName = vnode.attrs.col;
        // if (colName === undefined) return true;
        // if (tabName == 'buffer') return true;
        // if (tabName != colName) return false;
        // // return tabName == colName || tabName == 'buffer';
        //
        // // return true;
        // if (old.dom === undefined) return true;
        // let distance = Math.abs(tab.sel.index - vnode.attrs['index']);
        // // console.log(`distance is ${distance} ^${distance < 3}`);
        // return distance < 3;
    },

    view: function (vnode: Vnode<any, any>) {
        let tweet = vnode.attrs['tweet'];
        let selClass = vnode.attrs['isSel'] ? 'isSel' : '';
        let tab = app.openTab();

        let strongClass = tab.unreadById[tweet.id] === undefined ? '' : 'unread';
        let ago = new timeago().format(tweet.time, 'twitter');
        let userName = tweet.user.name;
        let atName = tweet.user.screenName;
        let userPic = tweet.user.pic;

        if (tweet.retweetStatus) {
            userName = tweet.retweetStatus.user.name;
            atName = tweet.retweetStatus.user.screenName;
            userPic = tweet.retweetStatus.user.pic;
        }
        // console.log(`view tweet ${tweet.id} ${tweet.text.slice(0, 30)}`);

        return m('article', {
            class: 'media ' + selClass, 'data-id': tweet.id,
            key: tweet.id,
        }, [
            m('figure.media-left', [
                m('p.image.is-48x48', [
                    m('img', {src: userPic})
                ]),
                m('p.has-text-centered.ago', `${ago}`)
            ]),
            m('div.media-content', [
                m('div.content', [
                    m('p', [
                        m('strong', {class: strongClass}, userName),
                        m('small', ` @${atName}`),
                        m('br'),
                        m.trust(tweet.text)
                    ])
                ]),
                tweet.pics === undefined ?
                    '' :
                    m('img', {src: tweet.pics[0].url}),
                tweet.quoted === undefined ?
                    '' :
                    m('article.media.embed', [
                        m('div.media-content', [
                            m('div.content', [
                                m('p', [
                                    m('strong', tweet.quoted.user.name),
                                    m('small', ` @${tweet.quoted.user.screenName}`),
                                    m('br'),
                                    m.trust(tweet.quoted.text)
                                ])
                            ])
                        ])
                    ]),
                tweet.retweetStatus === undefined ?
                    '' :
                    m('small', [
                        m('span.icon.is-small.svgi', [
                            m('img', {src: 'assets/svg/retweet.svg', alt: 'Retweet'}),
                        ]),
                        m('img.image.is-24x24.avatar', {src: tweet.user.pic}),
                        m('small', ' ' + tweet.user.name)
                    ]),
            ])
        ])
    }
};

let setupTimeago = function () {
    (timeago as any).register('twitter', function (num: number, index: number) {
        // number: the timeago / timein number;
        // index: the index of array below;
        return [
            ['now', 'now'],
            ['%ss', '%s'],
            ['1m', '1m'],
            ['%sm', '%sm'],
            ['1h', '1h'],
            ['%sh', '%sh'],
            ['1d', '1d'],
            ['%sd', '%sd'],
            ['1w', '1w'],
            ['%sw', '%sw'],
            ['1 mon', '1 mon'],
            ['%s mon', '%s mon'],
            ['1y', '1y'],
            ['%sy', '%sy']
        ][index];
    });
};


let app = {
    currentTab: 'timeline',
    tabStack: {} as { [index: string]: Timeline },
    wsSource: null as WebSocket | null,
    keepAliveTimer: -1,
    times: {} as { [index: string]: number; },
    buffer: {} as Timeline,
    time: 0,
    tabBarHeight: 0,

    openTab: function (): Timeline {
        return app.tabStack[app.currentTab];
    },

    newStack: function (title: string, tweets: Tweet[]): Timeline {
        let newTimeline = new Timeline(title, tweets, {index: 0, id: ''});
        newTimeline.back = app.openTab().name;
        app.tabStack[title] = newTimeline;
        app.switchTab(title);
        return newTimeline;
    },

    hasUnread: function (tabName: string): boolean {
        let tab = app.tabStack[tabName];
        return Object.keys(tab.unreadById).length > 0;
    },

    startTime: function () {
        app.time = new Date().valueOf();
    },

    addKeyboardShortcuts: function () {
        Mousetrap.bind('j', () => {
            app.cmd_moveSel(1);
        });

        Mousetrap.bind('k', () => {
            app.cmd_moveSel(-1);
        });
        Mousetrap.bind('t', () => {
            app.cmd_openTweetInBrowser();
        });
        Mousetrap.bind('l', () => {
            app.cmd_openLinks();
        });
        Mousetrap.bind('e', () => {
            app.cmd_openMedia();
        });
        Mousetrap.bind(['=', '`'], () => {
            app.cmd_nextUnread();
        });
        Mousetrap.bind('1', () => {
            app.cmd_moveTab();
        });
        Mousetrap.bind('2', () => {
            app.cmd_moveTab();
        });
        Mousetrap.bind('g g', () => {
            app.cmd_jumpTo(0);
        });
        Mousetrap.bind('shift+g', () => {
            app.cmd_jumpTo(1);
        });
        Mousetrap.bind('h', () => {
            app.cmd_enter();
        });
        Mousetrap.bind('i', () => {
            app.cmd_goBack();
        });
        Mousetrap.bind('u', () => {
            app.cmd_goPerson();
        });
        Mousetrap.bind('q', () => {
            app.startTime();
        });
        Mousetrap.bind('r', () => {
            app.refresh();
        });
        window.onbeforeunload = (e: BeforeUnloadEvent) => {
            // Store each tabs current index
            let tabNames = ['pol', 'timeline'];
            for (const name of tabNames) {
                let tab = app.tabStack[name];
                localStorage.setItem(`sel.${name}.id`, tab.sel.id);
                if (tab.sel.index == 0)
                    localStorage.setItem(`mostRecent.${name}.id`, tab.sel.id);

            }
            e.returnValue = 'Bob';
            return 'Bob';
        }
    },

    cmd_goPerson: () => {
        let tab = app.openTab();
        let tweet = tab.getTweet();
        if (tweet == null) return;

        let atName = tweet.user.screenName;
        if (tweet.retweetStatus) {
            atName = tweet.retweetStatus.user.screenName
        }
        let newTimeline = app.newStack(`U: ${atName}`, []);

        xhr.ajax({
            url: `${props.api}/friend/${atName}`,
            success: function (data) {
                newTimeline.friend = data as Friend;
                m.redraw();
            }
        })
    },

    cmd_jumpTo: (dir: number) => {
        let tab = app.openTab();
        let listLength = tab.list.length;

        if (listLength == 0) return;
        if (dir == 0) {
            tab.setSel(0);
        } else {
            tab.setSel(listLength - 1);
        }
        app.cmd_moveSel(0);
    },

    cmd_goBack: () => {
        if (app.currentTab == 'pol' || app.currentTab == 'timeline') return;
        let tab = app.openTab();
        app.switchTab(tab.back);
        delete app.tabStack[tab.name];
        m.redraw();
    },

    cmd_enter: () => {
        let tab = app.openTab();
        let tweet = tab.getTweet();
        if (tweet == null) return;

        let newTimeline = app.newStack(tweet.user.screenName, []);

        xhr.ajax({
            url: `${props.api}/conversation/${tab.name}/${tweet.id}`,
            success: function (data) {
                let tweets = data as Tweet[];
                newTimeline.setList(tweets);
                if (tweets.length > 0)
                    newTimeline.setSel(0);
                m.redraw();
            }
        })
    },

    cmd_moveTab: () => {
        if (app.currentTab == 'timeline') {
            app.switchTab('pol')
        } else {
            app.switchTab('timeline');
        }
    },

    cmd_nextUnread: function () {
        if (!app.hasUnread(app.currentTab)) {
            let otherTab = app.otherTab();
            if (app.hasUnread(otherTab))
                app.moveToUnread(otherTab);
            return
        }
        app.moveToUnread(app.currentTab);
    },

    moveToUnread: function (name: string) {
        if (name != app.currentTab) {
            app.cmd_moveTab();
        }
        let unreads = $(`.${name} .unread`);
        if (unreads.length == 0) return;
        let id = unreads.last().closest('[data-id]').attr('data-id');
        if (id !== undefined) {
            app.openTab().selectId(id);
        }
        app.cmd_moveSel(0);
    },

    cmd_openMedia: function () {
        let tweet = app.openTab().getTweet();
        if (tweet == null) return;
        if (tweet.pics === undefined) return;
        let pic = tweet.pics[0];
        let picMsg = {
            url: pic.url,
            width: pic.width,
            height: pic.height,
            screenWidth: screen.width,
            screenHeight: screen.height
        } as any;

        if (navigator.userAgent.toLowerCase().indexOf('qtwebengine') > -1) {
            xhr.ajax({
                method: 'post',
                url: `http://127.0.0.1:8000/open`,
                contentType: 'application/json',
                data: JSON.stringify(picMsg),
                error: ($$, txt, e) => {
                    console.log('error sending url to localhost', txt, e);
                }
            })
        } else {
            picMsg['kind'] = 'window';
            window.postMessage(picMsg, 'https://owl.gstaff.org');
        }
    },

    cmd_openLinks: function () {
        let tweet = app.openTab().getTweet();
        if (tweet == null) return;
        let urls = [] as string[];
        if (tweet.urls != null) tweet.urls.forEach((it) => {
            urls.push(it)
        });
        if (tweet.retweetStatus && tweet.retweetStatus.urls != null)
            tweet.retweetStatus.urls.forEach((it) => {
                urls.push(it)
            });
        app.openEachUrl(urls);
    },

    cmd_openTweetInBrowser: function () {
        let tweet = app.openTab().getTweet();
        if (tweet == null) return;
        let tweetUrl = `https://twitter.com/${tweet.user.screenName}/status/${tweet.id}`;
        console.log(`open ${tweetUrl}`);
        app.openEachUrl([tweetUrl]);
    },

    openEachUrl: function (urls: string[] | undefined) {
        if (urls === undefined) return;
        let set = {} as { [index: string]: string; };
        urls.forEach((it) => set[it] = it);

        for (let url of Object.keys(set)) {
            // chrome.runtime.sendMessage('ohggmdmhgfkjodpmmkaikiaooceemkkd', { kind: "tab", url: url });
            xhr.ajax({
                method: 'post',
                url: `http://127.0.0.1:8000/open`,
                // url: `${props.api}/open`,
                contentType: 'application/json',
                data: JSON.stringify({u: url}),
                error: ($$, txt, e) => {
                    console.log('error sending url', url, txt, e);
                    // chrome.runtime.sendMessage('mhjgbphagamkhijgdgimidmkkkknjdcd', {kind: "tab", url: url});
                    window.postMessage({kind: "tab", url: url}, 'https://owl.gstaff.org');
                    // window.postMessage({kind: "tab", url: url}, 'https://gstaff.org');
                }
            })
        }
    },

    cmd_moveSel: function (dir: number) {
        let tab = app.openTab();
        tab.moveSel(dir);
        let selectedTweet = tab.getTweet();
        if (selectedTweet == null) return;
        delete tab.unreadById[selectedTweet.id];

        m.redraw();
        // let $el = $(`[data-id=${selectedTweet.id}]`);
        let el = document.querySelectorAll(`[data-id='${selectedTweet.id}']`)[0] as HTMLElement;
        if (el === undefined) return;

        // let inView = app.isScrolledIntoView($el, dir);
        let inView = app.isScrolledIntoView(el, dir);
        // console.log('moveSel inView', inView);
        if (app.time != 0 && tab.sel.index == tab.list.length - 1) {
            let dur = new Date().valueOf() - app.time;
            console.log(`Took ${dur} ms to scroll ${tab.list.length} items. ${(dur / tab.list.length).toFixed(1)} mspi`);
            app.time = 0;
        }
        if (inView.is) return;

        let toScroll = app.scroller();
        if (dir > 0) {
            app.setScrollTop(toScroll.scrollTop + inView.by + 5);
        } else {
            app.setScrollTop(toScroll.scrollTop - (inView.by + app.tabBarHeight + 8)); //+extra for padding
        }
    },

    // FF requires $('html') whereas Chrome needs document.body
    scroller: () => {
        // let isFF = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        let isFF = true;
        return (isFF ? document.body.parentElement : document.body) as Element
    },

    setScrollTop: function (top: number) {
        app.scroller().scrollTop = top;
    },

    isScrolledIntoView: function (el: HTMLElement, dir: number) {
        let body = document.body,
            docViewTop = body.scrollTop,
            docViewBottom = docViewTop + window.innerHeight,
            elemTop = el.getBoundingClientRect().top + body.scrollTop,
            elemBottom = elemTop + el.offsetHeight,
            isInView = dir > 0 ?
                (elemBottom < docViewBottom) && (elemTop >= docViewTop) :
                (elemBottom < docViewBottom) && (elemTop >= docViewTop + app.tabBarHeight);

        // http://stackoverflow.com/questions/1823691/html-dom-width-height-of-visible-window
        // http://stackoverflow.com/questions/4884839/how-do-i-get-an-element-to-scroll-into-view-using-jquery
        // http://stackoverflow.com/questions/24768795/get-the-visible-height-of-a-div-with-jquery
        // http://stackoverflow.com/questions/5688362/how-to-prevent-scrolling-on-prepend

        return dir > 0 ?
            {is: isInView, by: elemBottom - docViewBottom} :
            {is: isInView, by: docViewTop - elemTop};
    },

    onclick: function (e: Event) {
        let tab = app.openTab();
        // walk up the dom for the first element that has a data-id
        let id = $(e.target).closest('[data-id]').attr('data-id');
        if (id !== undefined) {
            let index = tab.listMap[id];
            if (index === undefined) return;
            let tweet = tab.setSel(index);
            delete tab.unreadById[tweet.id];
        }
    },

    otherTab: function () {
        if (app.currentTab == 'timeline') return 'pol';
        return 'timeline';
    },

    calculateUnread: function (name: string) {
        let tab = app.tabStack[name];
        let tweets = tab.list;
        let lastRead = localStorage.getItem(`mostRecent.${name}.id`);
        console.log(`lastRead for ${name}=${lastRead}`);
        tab.unreadById = {};

        // Since the tweets are sorted, most recent to oldest;
        // start with the newest one, walking down the list
        // marking each tweet unread, until you reach the lastRead tweet
        for (let tweet of tweets) {
            if (tweet.id == lastRead)
                return;
            tab.unreadById[tweet.id] = tweet;
        }
    },

    refresh: function () {
        if (app.wsSource != null) {
            console.log('refresh app.wsSource.readyState=', app.wsSource.readyState);
            app.wsSource.close();
            app.wsSource = null;
        }
        app.ajaxTweetsSince('timeline');
        app.ajaxTweetsSince('pol');
        app.talkToServer();
    },

    talkToServer: () => {
        app.startListening();
        app.gotActivity();
    },

    gotActivity: function () {
        if (app.keepAliveTimer != -1) clearTimeout(app.keepAliveTimer);
        app.keepAliveTimer = setTimeout(() => {
            // 0=CONNECTING, 1=OPEN, 2=CLOSING, 3=CLOSED
            if (app.wsSource != null && app.wsSource.readyState != 1) {
                console.log('gotActivity readyState=', app.wsSource.readyState);
                m.redraw();
                app.wsSource.close();
                app.wsSource = null;
                app.startListening();
            }
            app.gotActivity();
        }, 15 * 1000);
    },

    startListening: function () {
        if (app.wsSource != null) return;
        app.wsSource = new WebSocket('wss://owl.gstaff.org/poll2');
        app.wsSource.onmessage = (e: any) => {
            let json = JSON.parse(e.data);
            console.log('ses msg', json);
            if (json['timeline']) app.ajaxTweetsSince('timeline');
            if (json['pol']) app.ajaxTweetsSince('pol');
        };
        app.wsSource.onopen = () => {
            m.redraw();
        };
    },

    timelineLoaded: function (tabName: string, data: Tweet[], redraw: boolean) {
        let tab = app.tabStack[tabName];
        tab.setList(data);
        if (data.length > 0) {
            tab.lastPulled = data[0].id;
        }
        if (redraw) {
            let previousId = localStorage.getItem(`sel.${tabName}.id`);
            if (previousId) {
                tab.selectId(previousId);
                // scroll the selection into view
                window.setTimeout(() => {
                    app.cmd_moveSel(0);
                }, 250);
            }
        }
        app.talkToServer();
    },

    ajaxTweetsSince: function (tab: string) {
        let tabStack = app.tabStack[tab];
//
        xhr.ajax({
            url: `${props.api}/tweets/${tab}/since/${tabStack.lastPulled}`,
            success: function (data) {
                app.newTweets(data as Tweet[], tabStack);
            }
        });
    },

    newTweets: (tweets: Tweet[], tabStack: Timeline) => {
        if (tweets.length == 0) return;

        // update lastPulled so you don't pull the same items again
        tabStack.lastPulled = tweets[0].id;

        app.buffer.setList(tweets);
        m.redraw();
        // Wait a few seconds for images to load and then prepend
        window.setTimeout(() => {
            tweets.forEach(tweet => {
                tabStack.unreadById[tweet.id] = tweet;
            });
            // Remove old tweets before prepending new ones to reduce scrolling
            app.cleanupOldTweets(tabStack);
            app.prependTweets(tweets, tabStack);
        }, 3000);
    },

    nt: function (count: number) {
        xhr.ajax({
            url: `${props.api}/newTweet/${count}`,
            success: function (data) {
                let tweets = data as Tweet[];
                let tab = app.tabStack['timeline'];
                app.newTweets(tweets, tab);
            }
        });
    },

    cleanupOldTweets: function (tab: Timeline) {
        if (tab.list.length < max_tweets) return;
        let end = max_tweets - 50;
        let listLength = tab.list.length;
        let index = end;
        if (tab.unreadById[tab.list[index].id] != null) {
            console.log("cleanup - too many unread");
            return;
        }
        let startTime = new Date().valueOf();
        for (; index < tab.list.length; index++) {
            delete tab.listMap[tab.list[index].id]
        }
        tab.list = tab.list.slice(0, end);
        console.log(`cleanup ${tab.name} removed ${listLength - tab.list.length} took ${new Date().valueOf() - startTime} ms`);
    },

    debugPrint: function (prefix: string, tab: Timeline) {
        let $el = $(`.${tab.name} .isSel`);
        let $body = $(app.scroller());
        let bodyHeight = $body.height() as any;
        let bodyScrollTop = $body.scrollTop() as any;

        let itemGlobalTop = 0;
        let itemLocalTop = 0;

        if ($el.length > 0) {
            itemGlobalTop = ($el.offset() as any).top;
            itemLocalTop = itemGlobalTop - (bodyScrollTop as any);
        }
        let bufferHeight = $('.buffer').height();

        console.log(`${prefix} tab=${tab.name} bodyHeight=${bodyHeight} scrollTop=${bodyScrollTop} itemScrollTop=${itemGlobalTop} itemInView=${itemLocalTop} bufferHeight=${bufferHeight}`);
    },

    /*
        Before prepending the new tweets gather:
            The selected item's global top
            Then calculate the selected item's local top by doing itemGlobalTop - body.scrollTop
        After prepending new tweets
            if the height of the page changed
                Gather the selected item's new global top
                Scroll the page so the selected item is locally at the same place as before
                By setting the page's scrollTop to (newItemGlobalTop - itemLocalTop) which
     */
    prependTweets: function (tweets: Tweet[], tab: Timeline) {
        let $buffer = $('.column.buffer');
        // remember the distance of the current selection
        let $el = $(`.${tab.name} .isSel`);
        let $body = $(app.scroller());
        let bodyHeight = $body.height() as any;
        let bodyScrollTop = $body.scrollTop() as any;

        let itemGlobalTop = 0;
        let itemLocalTop = 0;

        if ($el.length > 0) {
            itemGlobalTop = ($el.offset() as any).top;
            itemLocalTop = itemGlobalTop - (bodyScrollTop as any);
        }
        app.debugPrint('#1', tab);

        // swap new items into tab
        tab.prepend(tweets);

        // redraw and restore scroll position of current item
        m.redraw();

        app.debugPrint('#2', tab);

        // Is anything selected and did the height change after the redraw above
        // if so need to set the scroll top so items don't jump around
        // if (bufferHeight !==undefined && bufferHeight > 0) {
        //     let newElTop = $(`.${tab.name} .isSel`).offset()!!.top;
        //     console.log(`use bufferHeight to adjust setScrollTop to ${$body.scrollTop() as any + bufferHeight}`);
        //     app.setScrollTop($body.scrollTop() as any + bufferHeight);
        // }

        if ($el.length > 0 && $body.height()!! != bodyHeight!!) {
            let newItemGlobalTop = $(`.${tab.name} .isSel`).offset()!!.top;
            app.setScrollTop(newItemGlobalTop - itemLocalTop);
            app.debugPrint('#3', tab);
        }
        // sel index is wrong now, fix that
        tab.selectId(tab.sel.id);

        // check if the new tweets were in a non-focused tab
        if (app.openTab().name != tab.name) {
            console.log(`update scroll pos in other tab ${tab.name}`);
            console.log(`    ${tab.name} scrollTop=${tab.scrollTop} + ${$buffer.height()} = ${tab.scrollTop + $buffer.height()!!}`);

            // tab.scrollTop += $buffer.height();
            tab.scrollTop += ($buffer as any).actual('height')!!
        }
    },

    loadTimelines: () => {
        app.ajaxTimeline('pol');
        app.ajaxTimeline('timeline');
    },

    ajaxTimeline: function (name: string, redraw = true): JQueryXHR {
        return xhr.ajax({
            url: `${props.api}/timeline/${name}`,
            success: function (data) {
                app.timelineLoaded(name, data, redraw);
                app.calculateUnread(name);
                if (redraw)
                    m.redraw();
                app.times[name] = new Date().valueOf();
            },
            error: function (err) {
                console.log('Error loading data', err);
            }
        });
    },

    tabContent: function (): Vnode<{}, {}> {
        let tab = app.openTab();
        let pol = app.tabStack['pol'];
        let timeline = app.tabStack['timeline'];
        let isSubTab = tab.name != 'timeline' && tab.name != 'pol';

        let columns = [
            app.makeColumn(tab, timeline),
            app.makeColumn(tab, pol),
            app.makeColumn(tab, app.buffer)
        ];
        if (isSubTab)
            columns.push(app.makeColumn(tab, tab));

        return m('div', columns);
        // return m('div', { class: 'columns' }, columns);
    },

    makeColumn: function (currentTab: Timeline, timeline: Timeline): Vnode<any, any> {
        let showTab = currentTab.name == timeline.name;
        let columnName = timeline.name;

        if (timeline.name.startsWith("U: ")) {
            let tweet = app.tabStack[currentTab.back].getTweet();
            return m('div', {class: `column ${columnName} ${showTab ? '' : 'hide'}`}, [
                m(FriendDiv, {tweet: tweet})
            ]);
        } else {
            return m('div', {
                class: `column ${columnName} ${showTab ? '' : 'hide'}`, onclick: app.onclick.bind(app),
                onbeforeupdate: app.onbeforeupdateCol.bind(this)
            }, [
                timeline.list.map(function (tweet, index) {
                    let isSel = timeline.sel.id == tweet.id;
                    return m(TweetDiv, {tweet: tweet, isSel: isSel, col: columnName, index: index});
                })
            ]);
        }
    },

    onbeforeupdateCol: function (vnode: Vnode<any, any>, old: Vnode<any, any>): boolean {
        // return vnode.attrs.className.indexOf("hide") == -1;
        return true;
    },

    switchTab: function (newTabName: string): boolean {
        let tab = app.openTab();
        if (tab.name == newTabName) return false;

        tab.saveScrollTop();

        app.currentTab = newTabName;
        let newTab = app.openTab();

        m.redraw();
        if (newTab.scrollTop !== undefined) {
            app.setScrollTop(newTab.scrollTop);
            // document.body.scrollTop = newTab.scrollTop;
        }

        return false;
    },

    makeTab: function (name: string): Vnode<{}, {}> {
        let clazz = app.currentTab == name ? 'is-active' : '';
        // Possible values are CONNECTING (0), OPEN (1), or CLOSED (2)
        if (app.wsSource != null && app.wsSource.readyState != 1) clazz += ' offline';
        let label = name[0].toUpperCase() + name.slice(1);
        let tab = app.tabStack[name];
        if (name == 'timeline') label = 'Time';

        let unread = Object.keys(tab.unreadById).length;

        return m('li', {class: clazz},
            m('a', {onclick: app.switchTab.bind(this, name)}, [
                label,
                unread > 0 ?
                    m('span', {class: 'badge'}, `${unread}`) :
                    ''
            ])
        );
    },

    oncreate: () => {
        // app.tabBarHeight = $('.tabs').outerHeight();
        app.tabBarHeight = document.querySelectorAll('.tabs')[0].scrollHeight;
    },

    view: function () {
        let tabs = [
            app.tabStack['timeline'],
            app.tabStack['pol'],
        ];
        Object.keys(app.tabStack).forEach(name => {
            if (name == 'timeline' || name == 'pol' || name == 'buffer') return;
            tabs.push(app.tabStack[name]);
        });

        return m('div', {class: 'container desk'}, [
            m('div', {class: 'tabs'}, [
                m('ul',
                    tabs.map((tab) => {
                        return app.makeTab(tab.name)
                    })
                )
            ]),
            m('section', {class: 'section main'}, [
                app.tabContent(),
            ])
        ])
    },
};

app.times['start'] = new Date().valueOf();

app.tabStack['timeline'] = new Timeline('timeline', [], {index: 0, id: ''});
app.tabStack['pol'] = new Timeline('pol', [], {index: 0, id: ''});
app.buffer = new Timeline('buffer', [], {index: 0, id: ''});

app.addKeyboardShortcuts();
setupTimeago();
m.route.prefix("");
m.route(document.body, '/', {
    '/': app
});
// m.route(document.body, '/owl/', {
//     '/owl/': app
// });
app.loadTimelines();

(<any>window).app = app;
(<any>window).TweetDiv = TweetDiv;
